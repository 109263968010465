<template>
 <div class="page-container">
	 <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
	 <el-tabs v-model="active" @tab-click="handleClick">
	   <el-tab-pane label="耗材入库汇总表" :name="1"></el-tab-pane>
	   <el-tab-pane label="耗材入库明细表" :name="2"></el-tab-pane>
	 </el-tabs>
	 <el-form ref="form" :inline="true"  :model="search_key" prop="">
	 		 <div  style="position: absolute;right:20px;z-index: 999;">
	 		 	<el-button  type="primary" @click="handleExport">导出全部</el-button> 
	 		 </div>
	     <el-form-item label="">
	         <el-input v-model="search_key.name" placeholder="请输入物料名称" style="width:200px;"></el-input>
	     </el-form-item>
	     <el-button type="primary" @click="getData()">查询</el-button>
	 </el-form>
	  <el-table
	     :data="tableData" 
	     border
		row-key="id"
		:row-style="{height:'20px'}"
		:default-expand-all="false"
		:tree-props="{children: 'children', hasChildren: 'hasChildren'}">
		<el-table-column
		    prop="location_name"
		    label="入库仓库">
		</el-table-column>
	     <el-table-column
	         label="物料名称">
			 <template slot-scope="scope">
				 <span>{{scope.row.cseFile.name}}</span>
			 </template>
	     </el-table-column>
	      <el-table-column
	         label="物料分类">
			 <template slot-scope="scope">
			 	<span>{{scope.row.cseFile.cate_name}}</span>
			 </template>
	     </el-table-column>
	     <el-table-column
	         prop="number"
			 sortable
	         label="入库数量">
	     </el-table-column>
	     <el-table-column
	         prop="total_prices"
			 sortable
	         label="入库金额">
	     </el-table-column>
	  </el-table>
 </div>
</template>

<script>
export default {
	 inject:['reload'],
    data() {
        return {
			active:1,
			tableData:[],
			search_key:{
				name:''
			}
        }
    },
    created() {
		this.getData()
    },
    mounted() {

    },
    methods:{
		getData(){  
		    this.$api.get('CseReport/storage',{
				type:this.active,
				name:this.search_key.name
			},res=>{
		        if(res.code == 200){
		             this.tableData =  res.data.list
					
		        }else{
		            this.$message.error(res.msg)
		        }
		    })
		},
		handleClick(val){
			this.getData()
		},
		handleExport(){
			location.href = '/api/admin/CseReport/exportStorage?type='+this.active
		},
    },
}
</script>
<style scoped lang="scss">
</style>